/* You can add global styles to this file, and also import other style files */
/* @import 'https://fonts.googleapis.com/icon?family=Material+Icons'; */

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "break-points";
@import "color-variables";
@import "fonts";
@import "typography";
@import "custom";
@import "form-elements";
@import "modals";
@import '/node_modules/ngx-sharebuttons/themes/circles.scss';
@import '/node_modules/ngx-sharebuttons/themes/default.scss';


body {
  padding: 0;
  margin: 0;
  background-color: $black;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  font-family: "Gilroy" !important;
  color: $white;
  /* To enable hover in  ios mobile devices */
  @include bp(lg-max) {
    cursor: pointer;
  }
}

.layout-wrapper {
  min-height: 100vh;
  box-sizing: border-box;
}

*,
::after,
::before {
  box-sizing: border-box;
}

* {
  -webkit-overflow-scrolling: touch;
  &:focus {
    outline: none;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  color: $green-primary;
  text-decoration: none;
  @extend .trans;
  &:hover {
    text-decoration: none;
    color: $green-primary;
  }
}

img {
  max-width: 100%;
}

p {
  margin: 0;
}

/*----- Placeholder -----*/

::-webkit-input-placeholder {
  color: $placeholder-text;
  font-size: 12px;
  letter-spacing: 1px;
}
::-moz-placeholder {
  color: $placeholder-text;
  font-size: 12px;
  letter-spacing: 1px;
}
:-ms-input-placeholder {
  color: $placeholder-text;
  font-size: 12px;
  letter-spacing: 1px;
}
:-moz-placeholder {
  color: $placeholder-text;
  font-size: 12px;
  letter-spacing: 1px;
}

/*----- abc-fix-page-content-layout -----*/

.abc-fix-page-layout {
  min-height: calc(100vh - 220px);
  @include bp(sm-max) {
    min-height: auto;
    padding: 120px 0 30px;
  }
}

.abc-select-inner {
  justify-content: space-between;
}
.resend-and-skip-lbl a + a {
  margin-left: 10px;
}

/*----- Customize material CSS -----*/

.abc-input-field {
  .custom-file-label {
    &::after {
      position: absolute;
      width: auto;
      top: -1px;
    }
  }
}

// .abc-menu-overlay {
//   overflow: hidden;
//   position: fixed;
//   &::before {
//     content: "";
//     position: absolute;
//     height: 100%;
//     width: 100%;
//     background: rgba($black, 0.85);
//     z-index: 2;
//     transition: all 400ms;
//   }
// }

.container {  
  @include bp(xl-min) {
    max-width: 1280px;
  }  
  @include bp(xxlg-min){
    max-width: 1400px;
  }
  @include bp(xxxlg-min){
    max-width: 2000px;
  }
  @include bp(xxxxllg-min) {
    max-width: 3500px;
  }
  @include bp(xxxxlg-min){
    max-width: 5200px;
  }
}

/*----- Abc Progress-bar Css -----*/
.first-capitalize {
  text-transform: capitalize;
}

.progress {
  width: 95% !important;
  background: $white;
  border-radius: 3px;
  height: 20px;
  font-size: 13px;
  font-weight: 600;
  &.abc-progress-sm {
    height: 3px;
    border-radius: 0;
    background: $black-primary;
  }
}

.abc-upload-progressbar {
  .progress-bar {
    background-color: $green-primary;
  }
}
.abc-video-upload-progessbar {
  .progress-bar {
    background-color: $green-primary;
  }
}

/*----- Abc Film Category Listing Css -----*/

.abc-film-categories {
  .abc-film-category-list {
    .owl-nav {
      top: -60px;
      @include bp(md-max) {
        top: -59px;
      }
      @include bp(sm-max) {
        top: -53px;
      }
    }
    // padding: 0 15px;
    .content-wrap {
      padding: 10px;
      background: $white;
      border-radius: 4px;
      color: $gray2;
      .img-wrap {
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 15px;
        height: 0;
        padding-bottom: 150%;
        box-shadow: (0px 17px 9px rgba($black, 0.1));
        position: relative;
        img {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .detail-wrap {
        .top-block {
          position: relative;
          padding-right: 30px;
          h6 {
            color: $black;
            font-size: 14px;
            font-weight: 700;
            margin: 0 0 -5px;
          }
          p {
            color: $gray2;
          }
          .fav-button {
            background: url("#{$cdnUrl}/assets/images/abc-talkies-sprite.svg") no-repeat -523px -58px;
            width: 16px;
            height: 15px;
            display: inline-block;
            line-height: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: all 0.4s;
            cursor: pointer;
            &.active {
              background-position-y: -77px;
            }
          }
        }
        .bottom-block {
          .price {
            font-size: 28px;
            line-height: 32px;
            font-weight: 700;
            color: $green-primary;
            @include bp(sm-max) {
              font-size: 24px;
            }
          }
        }
      }
    }
    &.watch-list {
      .content-wrap {
        padding: 5px;
        .img-wrap {
          @include bp(md-max) {
            padding-bottom: 140%;
          }
        }
        .detail-wrap {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 5px;
          .top-block {
            h6,
            p {
              color: $white;
            }
            span {
              font-weight: 500;
            }
          }
        }
      }
    }
    &.wishlist {
      .content-wrap {
        padding: 5px;
        .detail-wrap {
          .bottom-block {
            .price {
              font-size: 19px;
            }
          }
        }
      }
    }
  }
}

/*------ Abc Film Content Listing Css ------*/
.abc-film-uploaded-content {
  .uploaded-content-top {
    @include bp(sm-min) {
      margin-top: 9px;
      padding-right: 135px;
    }
  }
}
.abc-film-content-list {
  .col-xl-4 {
    @include bp(lg-min) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include bp(xl-min) {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }
  .abc-film-content-box {
    margin-top: 46px;
    @include bp(xl-max) {
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $black-primary;
    }
    @include bp(lg-max) {
      align-items: flex-start;
    }
    @include bp(sm-max) {
      margin-top: 10px;
      padding-bottom: 10px;
    }
    @include bp(xxs-max) {
      flex-wrap: wrap;
    }
    &.has-bg {
      background: $black1;
      padding: 10px;
      border-radius: 5px;
    }
    &.watched {
      .abc-cancel-button {
        background: $black7;
        color: rgba($white, 0.1);
        pointer-events: none;
      }
    }
    .box-img {
      width: 173px;
      flex: 0 0 173px;
      max-width: 173px;
      border-radius: 5px;
      overflow: hidden;
      @include bp(lg-max) {
        width: 125px;
        flex: 0 0 125px;
        max-width: 125px;
      }
      @include bp(sm-max) {
        width: 135px;
        flex: 0 0 135px;
        max-width: 135px;
      }
      @include bp(xxs-max) {
        width: 100px;
        flex: 0 0 100px;
        max-width: 100px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    [class$="-icon"] {
      transition: all 0.5s;
      display: block;
      cursor: pointer;
    }
    .like-icon {
      background: url("#{$cdnUrl}/assets/images/abc-talkies-sprite.svg") no-repeat -465px 0;
      width: 22px;
      height: 21px;
      &:hover,
      &.active {
        background-position: -465px -28px;
      }
    }
    .dislike-icon {
      background: url("#{$cdnUrl}/assets/images/abc-talkies-sprite.svg") no-repeat -497px 0;
      width: 22px;
      height: 21px;
      position: relative;
      top: 4px;
      &:hover,
      &.active {
        background-position: -497px -28px;
      }
    }
    .share-icon {
      background: url("#{$cdnUrl}/assets/images/abc-talkies-sprite.svg") no-repeat -528px 0;
      width: 22px;
      height: 21px;
    }
    .box-content {
      height: 100%;
      padding-left: 20px;
      width: calc(100% - 173px);
      max-width: calc(100% - 173px);
      flex: 0 0 calc(100% - 173px);
      @include bp(lg-max) {
        width: calc(100% - 125px);
        flex: 0 0 calc(100% - 125px);
        max-width: calc(100% - 125px);
      }
      @include bp(sm-max) {
        padding-top: 10px;
        width: calc(100% - 135px);
        flex: 0 0 calc(100% - 135px);
        max-width: calc(100% - 135px);
      }
      @include bp(xxs-max) {
        padding-left: 0;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
      .abc-common-item-box {
        padding: 0;
        margin: 0;
        background: transparent;
        &:after {
          width: 108%;
          height: 108%;
          left: -5px;
        }
        h6 {
          font-size: 16px;
          line-height: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          @include bp(sm-min) {
            font-size: 18px;
          line-height: 20px;
          }
          @include bp(xlg-min) {font-size: 14px;
            line-height: 20px;}
          @include bp(xxlg-min) {font-size: 20px;
            line-height: 26px;}
          @include bp(xxxlg-min) {font-size: 25px;
            line-height: 30px;}
          
        }
        span {
          font-size: 14px;
          line-height: 20px;
          color: $gray3;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
         
          @include bp(xlg-min) {font-size: 12px;
            line-height: 16px;}
          @include bp(xxlg-min) {font-size: 14px;
            line-height: 20px;}
          @include bp(xxxlg-min) {font-size: 18px;
            line-height: 24px;}

        }
        p{
          font-size: 14px;
          line-height: 18px;
          @include bp(sm-min) {
            font-size: 16px;
            line-height: 20px;
          }
          @include bp(xlg-min) {font-size: 12px;
            line-height: 16px;}
          @include bp(xxlg-min) {font-size: 16px;
            line-height: 20px;}
          @include bp(xxxlg-min) {font-size: 20px;
            line-height: 26px;}
        }
      }
      .box-bottom-content {
        border-top: 1px $black6 solid;
        padding-top: 5px;
        margin-top: 15px;
        @include bp(sm-max) {
          margin-top: 10px;
        }
        .text {
          font-size: 15px;
          @include bp(xlg-min) {font-size: 12px;
            line-height: 16px;}
          @include bp(xxlg-min) {font-size: 16px;
            line-height: 20px;}
          @include bp(xxxlg-min) {font-size: 20px;
            line-height: 26px;}
          &:nth-child(odd) {
            padding-right: 10px;
          }
          &:nth-child(2n) {
            text-align: right;
          }
          &.red {
            color: $red;
          }
          span {
            color: $gray2;
          }
        }
      }
    }
  }
}

/*----- Abc Preference Common Css -----*/

.abc-perferable-common-warpper {
  .abc-perferable-inner {
    margin-left: -15px;
    margin-right: -15px;
    @include bp(sm-max) {
      margin-left: -7px;
      margin-right: -7px;
    }
  }
  &.grid-4-cols {
    .abc-per-box {
      @include bp(lg-min) {
        width: 25%;
        flex: 0 0 25%;
        max-width: 25%;
      }
      @include bp(md-max) {
        @include bp(sm-min) {
          width: 50%;
          max-width: 50%;
          flex: 0 0 50%;
        }
      }
    }
  }
  .abc-per-box {
    padding: 0 15px;
    width: 33.333%;
    flex: 0 0 33.333%;
    max-width: 33.333%;
    display: flex;
    margin-top: 30px;
    @include bp(sm-max) {
      padding: 0 7px;
      margin-top: 15px;
    }
    @include bp(xxs-max) {
      width: 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .box-content {
      background: $black1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-height: 100px;
      padding: 25px 10px;
      border-radius: 2px;
      position: relative;
      .heart-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        background: url("#{$cdnUrl}/assets/images/abc-talkies-sprite.svg");
        background-position: -499px -58px;
        width: 16px;
        height: 15px;
        transition: all 400ms;
      }
      p {
        font-weight: 500;
      }
    }
    &.check {
      .box-content {
        .heart-icon {
          background-position: -499px -77px;
        }
      }
    }
  }
}

/*------ Tooltip Style -----*/

.abc-tooltip {
  &.tooltip {
    z-index: 2;
    &.show {
      opacity: 1;
    }
    &.top {
      top: -6px !important;
    }
    &.bs-tooltip-top {
      .arrow {
        border-top-color: $white;
      }
    }
    &.bs-tooltip-right {
      .arrow {
        &:before {
          border-right-color: $white;
        }
      }
    }
    &.bs-tooltip-top {
      .arrow {
        &:before {
          border-top-color: $white;
        }
      }
    }
    &.bs-tooltip-left {
      .arrow {
        &:before {
          border-left-color: $white;
        }
      }
    }
    &.bs-tooltip-bottom {
      .arrow {
        &:before {
          border-bottom-color: $white;
        }
      }
    }
    .tooltip-inner {
      background: $white;
      border-radius: 0;
      color: $black;
      font-family: "Gilroy";
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      position: relative;
      max-width: 310px;
    }
  }
}

/*----- Back To Top Button Style -----*/

.scroll-to-top {
  position: fixed;
  bottom: 126px;
  right: 30px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 5;
}
.show-scrollTop {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.abc-back-to-top {
  .scrollTop {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border: none;
    background-color: $green-primary;
    transition: opacity 0.1 linear;
    z-index: 10000;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
    @extend .trans;
    @include bp(sm-max) {
      right: 30px !important;
      height: 35px !important;
      width: 35px !important;
    }
    &:after {
      height: calc(100% + 10px);
      width: calc(100% + 10px);
      top: -5px;
      left: -5px;
      border: 2px solid $green-primary;
      border-radius: 100%;
      content: "";
      position: absolute;
    }
    &:hover {
      opacity: 1;
    }
    .top-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      animation: upArrowAnim 2s infinite;
      animation-delay: 0.1s;
      &::before {
        content: "";
        position: absolute;
        width: 14px;
        height: 24px;
        background: url("#{$cdnUrl}/assets/images/abc-talkies-sprite.svg") no-repeat -573px -227px;
        -webkit-transform: rotate(-90deg) scale(0.7);
        transform: rotate(-90deg) scale(0.7);
      }
    }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes upArrowAnim {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.abc-video-player-common {
  .abc-footer {
    display: none;
  }
  .layout-wrapper {
    padding-bottom: 0 !important;
  }
}

.abc-sidebar-overlay {
  overflow: hidden;
  position: relative;
  touch-action: none;
  -webkit-touch-callout: none;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1002;
  }
}

.jio-ads {
  height: 100vh;
  overflow: hidden;
  .jads-container {
    & > .jads-row {
      background: #000;
      align-items: center;
      justify-content: center;
      height: calc(100vh - 40px);
      .jads-col-12 {
        width: 100%;
        height: 100%;
        & + .jads-col-12 {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
          height: auto;
        }
        video {
          width: 100%;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .jads-bg-grey {
      & > div {
        padding: 10px !important;
      }
    }
    .jads-cta-box {
      bottom: 5px;
      right: 15px;
      .jads-btn {
        z-index: 2;
      }
    }
  }
}
