h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}
h1,
.abc-h1-common-style {
    font-size: 45px;
    line-height: 56px;
    color: $white;
    font-weight: 700;
    @include bp(xlg-min) {
        font-size: 25px;
        line-height: 30px;
    }
    @include bp(xxlg-min) {
        font-size: 40px;
        line-height: 42px;
    } 
    @include bp(xxxlg-min) {
        font-size: 50px;
        line-height: 55px;
    }
    @include bp(xxxxlg-min) {
        font-size: 140px ;
        line-height: 150px ;
    }
    @include bp(sm-max) {
        font-size: 40px;
        line-height: 42px;
    }  
}
h2,
.abc-h2-common-style {
    font-size: 35px;
    line-height: 46px;
    color: $white;
    font-weight: 700;
    @include bp(xlg-min) {
        font-size: 20px;
        line-height: 26px;
    }
    @include bp(xxlg-min) {
        font-size: 35px;
        line-height: 40px;
    }
    @include bp(xxxlg-min) {
        font-size: 40px;
        line-height: 45px;
    }
    @include bp(sm-max) {
        font-size: 28px;
        line-height: 36px;
    }
}
h3,
.abc-h3-common-style {
    font-size: 25px;
    line-height: 36px;
    color: $white;
    font-weight: 700;
    @include bp(xlg-min) {
        font-size: 18px;
        line-height: 22px;
    }
    @include bp(xxlg-min) {
        font-size: 25px;
        line-height: 30px;
    }
    @include bp(xxxlg-min) {
        font-size: 35px;
        line-height: 42px;
    }
    @include bp(sm-max) {
        font-size: 20px;
        line-height: 30px;
    }
}
h4,.abc-h4-common-style {
    font-size: 20px;
    line-height: 25px;
    color: $white;
    @include bp(xlg-min) {
        font-size: 18px;
        line-height: 24px;
    }
    @include bp(xxlg-min) {
        font-size: 20px;
        line-height: 25px;
    }
    @include bp(xxxlg-min) {
        font-size: 35px;
        line-height: 40px;
    }
    @include bp(xxxxlg-min) {
        font-size: 50px;
        line-height: 55px;
    }
    @include bp(sm-max) {
        font-size: 18px;        
    }
}
h5 {
    font-size: 16px;
    line-height: 25px;
    color: $white;
    @include bp(xlg-min) {
        font-size: 16px;
        line-height: 22px;
    }
    @include bp(xxlg-min) {
        font-size: 18px;
        line-height: 26px;
    }
    @include bp(xxxlg-min) {
        font-size: 22px;
        line-height: 30px;
    }
}
h6 {
    font-size: 15px;
    line-height: 20px;
    color: $white;
    font-weight: 600;
}

p{
    @include bp(xxxxllg-min){
        font-size: 35px;
        line-height: 42px;
    }
    @include bp(xxxxlg-min){
        font-size: 45px;
        line-height: 55px;
    }
}