/*----- Font-face -----*/

@font-face {
    font-family: 'Gilroy';
    src: url('#{$cdnUrl}/assets/fonts/Gilroy-Regular.eot');
    src: url('#{$cdnUrl}/assets/fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-Regular.woff2') format('woff2'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-Regular.woff') format('woff'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-Regular.ttf') format('truetype'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-Regular.svg#Gilroy-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('#{$cdnUrl}/assets/fonts/Gilroy-Bold.eot');
    src: url('#{$cdnUrl}/assets/fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-Bold.woff2') format('woff2'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-Bold.woff') format('woff'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-Bold.ttf') format('truetype'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-Bold.svg#Gilroy-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('#{$cdnUrl}/assets/fonts/Gilroy-Medium.eot');
    src: url('#{$cdnUrl}/assets/fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-Medium.woff2') format('woff2'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-Medium.woff') format('woff'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-Medium.ttf') format('truetype'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-Medium.svg#Gilroy-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('#{$cdnUrl}/assets/fonts/Gilroy-SemiBold.eot');
    src: url('#{$cdnUrl}/assets/fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-SemiBold.woff2') format('woff2'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-SemiBold.woff') format('woff'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-SemiBold.ttf') format('truetype'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-SemiBold.svg#Gilroy-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('#{$cdnUrl}/assets/fonts/Gilroy-ExtraBold.eot');
    src: url('#{$cdnUrl}/assets/fonts/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-ExtraBold.woff2') format('woff2'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-ExtraBold.woff') format('woff'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-ExtraBold.ttf') format('truetype'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-ExtraBold.svg#Gilroy-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('#{$cdnUrl}/assets/fonts/Gilroy-Black.eot');
    src: url('#{$cdnUrl}/assets/fonts/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-Black.woff2') format('woff2'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-Black.woff') format('woff'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-Black.ttf') format('truetype'),
        url('#{$cdnUrl}/assets/fonts/Gilroy-Black.svg#Gilroy-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

// Inter

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-Thin.eot');
  src: url('../../assets/fonts/Inter-Thin.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Inter-Thin.woff2') format('woff2'),
      url('../../assets/fonts/Inter-Thin.woff') format('woff'),
      url('../../assets/fonts/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-ExtraLight.eot');
  src: url('../../assets/fonts/Inter-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Inter-ExtraLight.woff2') format('woff2'),
      url('../../assets/fonts/Inter-ExtraLight.woff') format('woff'),
      url('../../assets/fonts/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-Light.eot');
  src: url('../../assets/fonts/Inter-Light.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Inter-Light.woff2') format('woff2'),
      url('../../assets/fonts/Inter-Light.woff') format('woff'),
      url('../../assets/fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-Regular.eot');
  src: url('../../assets/fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Inter-Regular.woff2') format('woff2'),
      url('../../assets/fonts/Inter-Regular.woff') format('woff'),
      url('../../assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-Medium.eot');
  src: url('../../assets/fonts/Inter-Medium.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Inter-Medium.woff2') format('woff2'),
      url('../../assets/fonts/Inter-Medium.woff') format('woff'),
      url('../../assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-Bold.eot');
  src: url('../../assets/fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Inter-Bold.woff2') format('woff2'),
      url('../../assets/fonts/Inter-Bold.woff') format('woff'),
      url('../../assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-SemiBold.eot');
  src: url('../../assets/fonts/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Inter-SemiBold.woff2') format('woff2'),
      url('../../assets/fonts/Inter-SemiBold.woff') format('woff'),
      url('../../assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

