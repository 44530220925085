/*----- Popup custom design -----*/

.modal {
    z-index: 2000000000;
    .modal-content {
        border-radius: 0;
        border: 0;
    }
    .abc-opacity-zero{
        background: transparent !important;
        opacity: 0;
      }
    .abc-payment-custom-width {
        @include bp(md-max) {
            max-width: calc(100% - 50px);
        }
        @include bp(sm-max) {
            max-width: calc(100% - 20px);
        }
    }
}

.abc-popup-custom-design {
    .modal-header {
        background: $green-primary;
        color: $white;
        padding: 15px 30px;
        border-radius: 0;
        border: 0;
        @include bp(sm-max) {
            padding: 10px 20px;
        }
    }
    .close {
        background: $green-primary;
        opacity: 1;
        font-size: 36px;
        font-weight: 500;
        padding: 0;
        margin: 0;
        text-shadow: none;
        color: $white;
        outline: 0;
    }
    .modal-content {
        background: $white;
        border-radius: 2px;
        border: 0;
    }
    .modal-body {
        padding: 15px 30px;
        @include bp(sm-max) {
            padding: 15px 20px;
        }
    }
}

/*----- Abc-review-order-popup (Movie-detail page) -----*/
.abc-review-order-popup {
    .modal-body {
        max-height: 85vh;
        height: 100%;
        overflow: auto;
    }
    .abc-popup-content-area {
        color: $black;
        @include bp(md-max) {
            margin-bottom: 30px;
        }
        .abc-gray-fonts {
            color: $gray3;
        }
        .abc-movie-details {
            color: $black;
            font-weight: 600;
            @include bp(xxs-max) {
                margin-bottom: 5px;
            }
            div {
                margin-right: 5px;
            }
            .abc-movie-name {
                position: relative;
                margin-right: 15px;
                padding-right: 15px;
                @include bp(xs-max) {
                    width: 100%;
                    margin-right: 0;
                    padding-right: 0;
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 0;
                    height: 80%;
                    width: 2px;
                    background: $gray3;
                    transform: translateY(-50%);
                    @include bp(xs-max) {
                        display: none;
                    }
                }
            }
        }
        .abc-review-order-left {
            @include bp(md-min) {
                padding-right: 15px;
            }
            @include bp(lg-min) {
                padding-right: 35px;
            }
            .abc-order-common-box {
                padding: 15px;
                & ~ .abc-order-common-box {
                    margin-top: 30px;
                    @include bp(sm-max) {
                        margin-top: 15px;
                    }
                }
            }
            .abc-add-wallet-credit {
                background: #e7f6f3;
                @include bp(xs-max) {
                    flex-direction: column;
                    align-items: flex-start !important;
                }
                strong {
                    line-height: 25px;
                }
                .abc-font-style-12 {
                    color: $black;
                    margin-top: 0;
                    font-weight: 600;
                }
                .abc-amount-add {
                    @include bp(xs-max) {
                        margin-top: 10px;
                    }
                    button.abc-add-cash {
                        min-width: 190px;
                        @include bp(lg-max) {
                            min-width: 175px;
                        }
                    }
                    .abc-payment-input-field {
                        @include bp(lg-max) {
                            margin-top: 10px;
                        }
                    }
                }
            }
            .abc-topup-wallet-checkbox {
                span {
                    font-weight: 600;
                    color: $black;
                }
            }
            .abc-payment-method {
                border: 2px $gray3 solid;
                min-height: 100px;
                &.abc-payment-active {
                    border-color: $green-primary;
                }
                .abc-wallet-payment-logo {
                    min-width: 150px;
                    @include bp(lg-max) {
                        min-width: 130px;
                    }
                    @include bp(sm-max) {
                        margin-top: 15px;
                    }
                }
                .abc-info-icon {
                    width: 18px;
                    height: 18px;
                    background: $gray1;
                    border-radius: 100%;
                    position: absolute;                    
                    top: 5px;
                    right: 5px;
                    cursor: pointer;
                    &:hover {
                        background: $black;
                    }
                    @include bp(sm-max) {
                        top: 1px;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        background: url("#{$cdnUrl}/assets/images/abc-talkies-sprite.svg") no-repeat;
                        background-position: -503px -162px;
                        width: 9px;
                        height: 21px;
                        transform: scale(0.6);
                    }
                }
            }
        }
        .abc-payment-button {
            background: $green-primary;
            color: $white;
            border: 0;
            letter-spacing: 1px;
            font-size: 14px;
            height: 40px;
            line-height: 40px;
            @extend .trans;
            span {
                font-size: 22px;
                margin-right: 5px;
            }
            &:hover {
                background: $black;
            }
        }
        .abc-review-order-right {
            position: relative;
            height: 100%;
            @include bp(md-min) {
                padding-left: 15px;
            }
            @include bp(lg-min) {
                padding-left: 35px;
            }
            @include bp(md-max) {
                padding-top: 30px;
                margin-top: 30px;
                height: auto;
            }
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: -15px;
                height: 100%;
                width: 1px;
                background: $gray3;
                @include bp(md-max) {
                    left: 0;
                    width: 100%;
                    height: 2px;
                    top: 0;
                }
            }
            .abc-order-summary {
                margin-top: 50px;
                padding-top: 20px;
                background-image: linear-gradient(to right, $green-primary 80%, rgba(255, 255, 255, 0) 0%);
                background-position: top;
                background-size: 14px 1px;
                background-repeat: repeat-x;
                @include bp(sm-max) {
                    margin-top: 30px;
                    padding-top: 15px;
                }
                .abc-h4-common-style {
                    color: $black;
                }
                div {
                    min-width: 50%;
                    margin-top: 5px;
                    span {
                        margin-right: 3px;
                    }
                }
                .abc-price {
                    font-weight: 600;
                }
            }
        }
        .abc-payment-input-field {
            label {
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 5px;
            }
            button {
                margin-left: 15px;
                min-width: 85px;
            }
            input {
                width: 100%;
                border-radius: 0;
                border: 1px solid $gray3;
                padding: 0 15px;
                &:focus {
                    border-color: $green-primary;
                }
            }
        }
        .abc-checkbox {
            input {
                box-shadow: 0 0 0 1px $blue-light;
                min-width: 18px;
                &:hover {
                    background: $white;
                }
            }
            input:checked {
                box-shadow: 0 0 0 1px $green-primary;
                &:hover {
                    background: $green-primary;
                }
            }
            .abc-wallet-text-input {
                border: 1px $green-primary solid;
                box-shadow: none;
                font-size: 14px;
                padding: 0 10px;
                width: 70px;
                text-align: center;
                margin: 0 5px 0 3px;
                position: relative;
                top: -2px;
            }
            span {
                // color: $gray3;
                font-size: 15px;
                line-height: 24px;
                font-weight: 500;
                position: relative;
                top: 3px;
                @include bp(lg-max) {
                    font-size: 16px;
                    line-height: 20px;
                }
                @include bp(xxs-max) {
                    font-size: 14px;
                    line-height: 18px;
                }
                strong {
                    color: $black;
                }
            }
            .abc-topup-payment {
                input {
                    box-shadow: none;
                }
            }
        }
    }
}

/*----- Placeholder -----*/

::-webkit-input-placeholder {
    color: $gray3;
    font-size: 12px;
    letter-spacing: 1px;
}
::-moz-placeholder {
    color: $gray3;
    font-size: 12px;
    letter-spacing: 1px;
}
:-ms-input-placeholder {
    color: $gray3;
    font-size: 12px;
    letter-spacing: 1px;
}
:-moz-placeholder {
    color: $gray3;
    font-size: 12px;
    letter-spacing: 1px;
}
