input,
textarea,
select {
  letter-spacing: 1px;
  font-family: "Gilroy", sans-serif !important;
}

.ng-value-container {
  @include bp(sm-max) {
    padding: 0px 12px !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $black2 inset !important;
  box-shadow: 0 0 0 30px $black2 inset !important;
  -webkit-text-fill-color: $white !important;
}

/* File uploader cursor CSS */

input {

  &[type="file"],
  [type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.custom-file-input {
  &:focus {
    ~.custom-file-label {
      border-color: $green-primary !important;
      background: $black2;
      box-shadow: none;
    }
  }

  &:lang(en) {
    ~.custom-file-label {
      &::after {
        content: "Upload";
      }
    }
  }
}

.abc-input {
  background-color: $black4;
  border: 1px solid $black7;
  border-radius: 0;
  padding: 15px 12px;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  color: $white;
  width: 100%;
  box-shadow: none;
  outline: 0;
  display: block;
  height: 46px;

  @include bp(sm-min) {
    height: 46px;
    padding: 8px 12px;
  }

  @include bp(xlg-min) {
    padding: 8px 12px;
    height: 40px;

    &::placeholder {
      font-size: 14px;
    }
  }

  @include bp(xxlg-min) {
    padding: 15px 20px;
    height: 52px;
    font-size: 16px;
    line-height: 20px;
  }

  @include bp(xxxlg-min) {
    padding: 20px 20px;
    height: 65px !important;
    font-size: 22px;

    &::placeholder {
      font-size: 22px;
    }
  }

  &:disabled {
    background: $black3;
    border-color: $black3;
    cursor: not-allowed;
  }
}

label.only-button.abc-small-button.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.abc-common-btn {
  background-color: $green-primary;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  color: $white;
  outline: 0;
  border: 0;
  width: auto;
  padding: 0 20px;
  cursor: pointer;
  transition: all 400ms;
  display: flex;
  align-items: center;
  justify-content: center;

  @include bp(xlg-min) {
    font-size: 12px;
    padding: 7px 12px;
  }

  @include bp(xxlg-min) {
    font-size: 16px;
    padding: 14px 20px;
    width: auto !important;
  }

  @include bp(xxxlg-min) {
    font-size: 22px;
    padding: 23px 20px;
  }

  @include bp(sm-max) {
    padding: 10px 12px;
    font-size: 14px;
  }
}

.abc-OTP-input {
  >div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @include bp(xlg-min) {
      justify-content: flex-start;
      gap: 20px;
    }

    @include bp(xxlg-min) {
      justify-content: space-between;
      gap: unset;
      margin-bottom: 10px;
    }
  }

  .otp-input {
    width: 60px !important;
    height: 55px !important;
    border: 1px solid $black-primary !important;
    border-radius: 0 !important;
    margin: 0 !important;
    font-size: 15px !important;
    letter-spacing: 1px;
    line-height: 25px !important;

    @include bp(xlg-min) {
      width: 40px !important;
      height: 35px !important;
      gap: 10px;
    }

    @include bp(xxlg-min) {
      width: 60px !important;
      height: 55px !important;
    }

    @include bp(xxs-max) {
      width: 15% !important;
      height: 46px !important;
    }

    &:focus {
      border-color: $green-primary !important;
      background: $black2;
      box-shadow: none;
    }
  }
}

label,
button,
h4 {
  sup {
    font-size: 23px;
    line-height: 14px;
    top: 3px;
    color: $red;

    @include bp(xlg-min) {
      top: 5px;
    }

    @include bp(xxlg-min) {
      top: -1px;
    }
  }
}

.abc-input-field {
  position: relative;

  ::placeholder {
    font-size: 16px;
  }

  button.referral-button {
    @include bp(xxxlg-min) {
      font-size: 25px;
    }
  }

  label,
  .agent-label {
    font-size: 15px;

    @include bp(xlg-min) {
      font-size: 14px;
      margin-bottom: 5px;
    }

    @include bp(xxlg-min) {
      font-size: 16px;
      margin-bottom: 10px;
    }

    @include bp(xxxlg-min) {
      font-size: 20px !important;
      margin-bottom: 10px !important;
    }
  }

  >label {
    margin-bottom: 5px;
    font-size: 15px;
    letter-spacing: 1px;
    line-height: 25px;
    color: $white;
    display: block;

    @include bp(xlg-min) {
      font-size: 14px;
      margin-bottom: 5px !important;
    }

    @include bp(xxlg-min) {
      font-size: 16px;
    }

    @include bp(xxxlg-min) {
      font-size: 22px;
      margin-bottom: 10px !important;
    }
  }

  textarea {
    height: 195px !important;
  }

  input,
  textarea,
  select,
  .custom-file-label {
    // top: 6px;
    @extend .abc-input;

    &::after {
      @extend .abc-common-btn;
      height: unset !important;
    }
  }

  input,
  textarea {
    &:focus {
      border-color: $green-primary !important;
      background: $black2;
      box-shadow: none;
    }
  }

  textarea {
    height: auto;
  }

  .password-field {
    position: relative;

    input {
      padding-right: 38px;
    }

    .visibility-icon {
      background: url("#{$cdnUrl}/assets/images/abc-talkies-sprite.svg") no-repeat -531px -158px;
      width: 22px;
      height: 19px;
      display: block;
      line-height: 0;
      position: absolute;
      right: 12px;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;

      &.active {
        background-position: -531px -179px;
        height: 16px;
      }
    }
  }

  select {
    &.form-control {
      height: 55px;

      &:focus {
        border-color: $green-primary !important;
        background: $black2;
        box-shadow: none;
      }
    }
  }

  .abc-select-dropdown {
    .ng-select-container {
      display: flex;
      align-items: center;
      padding: 10px 10px !important;
      height: 46px;

      @include bp(xlg-min) {
        height: 40px;
      }

      @include bp(xxlg-min) {
        height: 52px;
      }

      @include bp(xxxlg-min) {
        height: 65px !important;
      }

      @extend .abc-input;
      align-items: center;
    }

    .ng-value-container {
      padding: unset !important;
    }

    .ng-value-container .ng-value {
      color: $white !important;
    }

    .ng-placeholder {
      font-size: 16px;
      position: static !important;
      padding: 0 !important;

      @include bp(xlg-min) {
        font-size: 12px;
      }

      @include bp(xxlg-min) {
        font-size: 15px;
      }

      @include bp(xxxlg-min) {
        font-size: 18px;
      }
    }

    &.multiselect {
      position: relative;
      display: inline-block;

      &::before {
        content: attr(data-label);
        color: $white;
        left: 0;
        top: 0;
        z-index: 1;
        display: inline;
        margin-right: 30px;
      }

      .ng-select-container {
        border: none;
        background: none;
        padding: 0;
        display: inline;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100% !important;

        .ng-arrow-wrapper {
          position: absolute;
          right: 0;
          top: 0;

          .ng-arrow {
            border: 2px solid $white;
            border-width: 0 2px 2px 0;
            height: 8px;
            width: 8px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            top: -4px;
          }
        }
      }

      .ng-clear-wrapper {
        right: 5px;
        top: 0;
        position: absolute;
      }

      input {
        height: auto;
        opacity: 0;
      }

      &.ng-select-multiple {
        .ng-select-container {
          &.ng-has-value {
            padding: 0px !important;

            .ng-value-container {
              display: none;
            }
          }
        }

        .ng-dropdown-panel-items {
          .ng-option {
            padding-left: 40px;
            position: relative;

            &:after,
            &:before {
              position: absolute;
              content: "";
              transition: all 0.4s;
              -webkit-transition: all 0.4s;
            }

            &:after {
              height: 16px;
              width: 16px;
              border-radius: 2px;
              border: 1px solid $green-primary;
              left: 9px;
              top: 9px;
            }

            &:before {
              position: absolute;
              content: "";
              height: 6px;
              width: 11px;
              border-radius: 2px;
              border-bottom: 2px solid $white;
              border-left: 2px solid $white;
              left: 11px;
              top: 13px;
              z-index: 1;
              transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              opacity: 0;
            }

            &.ng-option-selected {
              background: none;

              .ng-option-label {
                font-weight: 400;
              }

              &:after {
                background: $green-primary;
              }

              &::before {
                opacity: 1;
              }

              &:hover {
                background: $green-primary;

                &:after {
                  border-color: $white;
                  background: $white;
                }

                &:before {
                  border-left-color: $green-primary;
                  border-bottom-color: $green-primary;
                }
              }
            }
          }
        }

        .ng-dropdown-panel {
          left: auto;
          right: 0;
          white-space: nowrap;
          width: auto;

          &.ng-select-bottom {
            top: calc(100% + 10px);
          }
        }

        .ng-clear-wrapper {
          .ng-clear {
            color: $white;
          }
        }
      }

      &.ng-select-opened {
        .ng-select-container {
          .ng-arrow-wrapper {
            .ng-arrow {
              top: -2px;
              transform: rotate(-135deg);
              -webkit-transform: rotate(-135deg);
            }
          }
        }
      }
    }
  }

  .ng-select {
    &.ng-select-single {
      .ng-select-container {
        display: flex;
        align-items: center;
        padding: 0px 20px;

        .ng-value-container {
          .ng-input {
            padding: 0px 65px 0px 10px !important;
            top: unset !important;
            vertical-align: middle;
            display: flex;
            align-items: center;

            input {
              padding: unset !important;
              display: flex;
              align-items: center;
            }
          }
        }

        height: 46px;

        @include bp(xlg-min) {
          height: 40px;
        }

        @include bp(xxlg-min) {
          height: 52px;
        }

        @include bp(xxxlg-min) {
          height: 65px !important;
        }
      }
    }

    &.ng-select-disabled {
      >.ng-select-container {
        background: #3d3d3d;
      }
    }

    .ng-arrow-wrapper {
      width: 15px;
      padding: 0;

      .ng-arrow {
        border-color: $black3 transparent transparent;
        border-width: 5px 5px 2.5px;
        transition: all 400ms;
      }
    }

    &.ng-select-opened {
      >.ng-select-container {
        background: transparent;
        border-color: $green-primary;

        .ng-arrow {
          border-color: $black3 transparent transparent;
          border-width: 5px 5px 2.5px;
          transform: rotate(180deg);
        }
      }
    }

    &.ng-select-focused {
      &:not(.ng-select-opened) {
        >.ng-select-container {
          border-color: $green-primary !important;
          background: $black2;
          box-shadow: none;
        }
      }
    }

    &.ng-select-multiple {
      .ng-select-container {
        .ng-value-container {
          .ng-value {
            background-color: $green-primary;
            border-radius: 0;
            font-size: 12px;
            margin-bottom: 0px;
            margin-right: 0px;
            color: #fff;

            @include bp(xlg-min) {
              height: 20px;

              span {
                height: 20px;
                display: flex;
                align-items: center;
              }
            }

            @include bp(xlg-min) {
              height: 25px;

              span {
                height: 25px;
                display: flex;
                align-items: center;
              }
            }

            @include bp(xxxlg-min) {
              font-size: 18px;

              span {
                padding: 5px 5px;
              }
            }

            .ng-value-icon {
              &.left {
                border-right: 1px solid rgba($white, 0.3);
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }

  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option.ng-option-marked {
        @extend .trans;
      }

      .ng-option {
        &.ng-option-marked {
          .ng-option-label {
            font-weight: 400;
          }

          &:hover {
            background-color: $green-primary;
            color: $white;

            &:after {
              border-color: $white;
            }
          }
        }
      }
    }
  }

  .custom-file {
    height: auto;

    input {
      cursor: pointer;
    }
  }

  .custom-file-input:disabled~.custom-file-label,
  .custom-file-input[disabled]~.custom-file-label {
    background-color: #3d3d3d !important;
  }

  input.custom-file-input:disabled {
    cursor: not-allowed;
  }

  input.custom-file-input:disabled+label::after {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .custom-file-label {
    font-size: 15px;
    color: rgba($white, 0.5);
    margin-bottom: 0;

    &.selected {
      color: $white;

      span {
        color: $white;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        width: -webkit-calc(100% - 100px);
        width: calc(100% - 100px);
      }
    }

    @include bp(xlg-min) {
      font-size: 12px;
    }

    @include bp(xxlg-min) {
      font-size: 16px;
    }

    @include bp(xxxlg-min) {
      font-size: 20px;
    }
  }

  .iti {
    width: 100%;

    &.iti--allow-dropdown {
      input[type="tel"] {
        padding-right: 120px;
        color: $white;

        &:disabled {
          color: #ffffff;
        }

        @include bp(sm-max) {
          padding-right: 110px;
        }
      }
    }
  }

  .iti__country-name {
    color: $black;
    font-size: 15px !important;
    font-weight: 500;
  }

  .iti__selected-flag {
    outline: none;
  }

  .abc-timepicker {
    >table {
      width: 100%;

      .btn {
        color: $white;
        background: $black-primary;
        border-radius: 0;
        outline: 0;
        box-shadow: none;
        height: 55px;
        padding: 0 15px;
        @extend .trans;

        &:hover {
          background: $green-primary;
        }

        @include bp(sm-max) {
          height: 46px;
        }
      }
    }
  }
}

/*----- Button common CSS -----*/
.c-ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: transparent;
  z-index: 2;
}

.c-ripple__circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba($white, 0.25);

  .c-ripple.is-active & {
    animation: a-ripple 0.4s ease-in;
  }
}

@keyframes a-ripple {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  100% {
    width: 200%;
    padding-bottom: 200%;
    opacity: 0;
  }
}

button,
button:focus {
  outline: 0;
}

.abc-form-button {

  .btn-and-icon,
  .only-button {
    @extend .abc-common-btn;
    height: 46px;

    @include bp(xlg-min) {
      height: 40px;
    }

    @include bp(xxlg-min) {
      height: 52px;
    }

    @include bp(xxxlg-min) {
      height: 65px !important;
    }

    @include bp(xxxxlg-min) {
      height: 115px !important;
    }
  }

  .only-button {

    &::before,
    &:after {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 1;
      transform: translate(-105%, 0px);
      border-right-width: 1px;
      border-right-style: solid;
      border-right-color: $white;
      background-color: rgba($white, 0.25);
      transition: all 0.5s ease 0s;
    }

    &::after {
      transition-delay: 0.2s;
    }

    &:hover {

      &:after,
      &:before {
        opacity: 0;
        transform: translate(0px, 0px);
      }
    }
  }

  button {
    &.btn-and-icon {
      &:after {
        content: "";
        width: 25px;
        height: 25px;
        background: url("/assets/images/button--left-arrow.svg") no-repeat;
        background-size: 100%;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        position: relative;

        @include bp(sm-max) {
          transform: scale(0.9);
          top: 0;
          transform-origin: center center;
        }
      }

      &:hover {
        &:after {
          animation: abc-arrow-move 0.5s ease-in-out infinite;
        }
      }
    }

    &.abc-back-button {
      &:after {
        display: none;
      }

      &::before {
        content: "";
        background-size: 100%;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        margin-left: 0;
        border-left: 3px #fff solid;
        border-top: 3px #fff solid;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        transform: rotate(-45deg);
        backface-visibility: hidden;
        will-change: transform;
        margin-right: 5px;
      }

      &:hover {
        &::before {
          animation: flimmaker-back-button 0.5s ease-in-out infinite;
          transform: rotate(-45deg);
        }
      }
    }

    &.abc-watch-now-button {
      min-width: 230px;
      font-size: 13px;

      @include bp(xlg-min) {
        min-width: 150px;
        height: 40px;
        font-size: 12px;
      }

      @include bp(xxlg-min) {
        font-size: 18px;
        height: 50px;
      }

      @include bp(xxxlg-min) {
        font-size: 30px;
        height: 60px;
      }

      @include bp(xxxxlg-min) {
        font-size: 60px;
        height: 90px;
      }

      span {
        display: flex;
      }

      strong {
        font-size: 25px;
        font-weight: 600;
        position: relative;
        top: -1px;

        @include bp(xlg-min) {
          font-size: 18px;
        }

        @include bp(xxlg-min) {
          font-size: 24px;
        }

        @include bp(xxxlg-min) {
          font-size: 35px;
        }

        @include bp(xxxxlg-min) {
          font-size: 50px;
        }

        @include bp(sm-max) {
          font-size: 18px;
        }
      }

      &::before {
        content: "";
        background: url("#{$cdnUrl}/assets/images/abc-talkies-sprite.svg") no-repeat -562px -1px;
        width: 27px;
        height: 28px;
        display: inline-block;
        margin-right: 10px;
      }

      &:after {
        display: none;
      }

      &:hover {
        &::before {
          animation: abc-arrow-move 0.5s ease-in-out infinite;
        }
      }

      &:disabled {
        background: $gray2;
        cursor: not-allowed;
        opacity: 1;
        pointer-events: auto;
        overflow: inherit;

        &:before {
          animation: none;
        }
      }
    }
  }

  &.abc-send-OTP {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .abc-small-button {
    height: 40px;
  }

  .btn-gray-bg {
    @extend .abc-common-btn;
    background: $black1;
    color: $gray2;
  }

  .btn-black-bg {
    @extend .abc-common-btn;
    background-color: $black;
    color: $white;
  }

  .btn-white-bg {
    @extend .abc-common-btn;
    background: $white;
    color: $green-primary;
  }

  button:disabled,
  button[disabled] {
    opacity: 0.8;
    cursor: not-allowed;

    &::before,
    &::after {
      display: none;
    }
  }
}

@keyframes abc-arrow-move {

  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}

@keyframes flimmaker-back-button {

  0%,
  100% {
    transform: translate(0, 0) rotate(-45deg);
  }

  50% {
    transform: translate(-5px, 0) rotate(-45deg);
  }
}

/*----- Radio , Checkbox style css -----*/

.abc-checkbox,
.abc-radio {

  label,
  div {
    margin: 0;
    cursor: pointer;

    span {
      font-size: 15px;

      @include bp(xlg-min) {
        font-size: 14px;
      }

      @include bp(xxlg-min) {
        font-size: 16px;
      }

      @include bp(xxxlg-min) {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  .option-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    top: 5px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 18px;
    width: 18px;
    transition: all 0.15s ease-out 0s;
    background: $white;
    border: none;
    color: $white;
    cursor: pointer;
    display: inline-block;
    margin-right: 0.5rem;
    outline: none;
    border-radius: 0;
    z-index: 1;

    @include bp(xxlg-min) {
      top: 3px;
    }

    &:hover {
      background: $blue-light;
    }

    &:checked {
      background: $green-primary;

      +span {
        color: $green-primary;

        a {
          color: $white;
        }
      }

      &:disabled {
        background: $green-dark;
        cursor: not-allowed;

        &:hover {
          background: $green-dark !important;
        }
      }

      &::before {
        height: 18px;
        width: 18px;
        position: absolute;
        color: $white;
        content: "";
        background: url("/assets/images/abc-checkmark.svg") no-repeat center center;
        background-size: 12px 12px;
        display: inline-block;
        font-size: 15px;
        text-align: center;
        line-height: 18px;
      }

      &::after {
        @include bp(lg-min) {
          // -webkit-animation: click-wave 0.65s;
          // -moz-animation: click-wave 0.65s;
          // animation: click-wave 0.65s;
        }

        background: $green-primary;
        content: "";
        display: block;
        position: relative;
        z-index: 100;
      }
    }

    &:disabled {
      background: $white-primary;
      cursor: not-allowed;

      &:hover {
        background: $white-primary !important;
      }

      +span {
        opacity: 0.5;

        &:hover {
          cursor: not-allowed;
        }

        strong {
          opacity: 0.5;
        }
      }
    }
  }

  &.abc-checkbox-disabled,
  &.abc-radio-disabled {
    label {
      cursor: not-allowed;
    }
  }
}

@keyframes click-wave {
  0% {
    height: 40px;
    width: 40px;
    opacity: 0.35;
    position: relative;
  }

  100% {
    height: 200px;
    width: 200px;
    margin-left: -80px;
    margin-top: -80px;
    opacity: 0;
  }
}

.abc-radio {
  .option-input {
    &:checked {
      &::before {
        content: "";
        width: 8px;
        height: 8px;
        background: $white;
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
      }
    }

    &.radio {
      border-radius: 50%;

      &::after {
        border-radius: 50%;
      }
    }
  }
}

/*----- Dropdown panel style css -----*/

.ng-dropdown-panel {
  &.ng-select-bottom {
    border: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
}

.abc-select-box {
  .mat-form-field {
    &.mat-focused {
      .mat-form-field-label {
        color: $white;
      }

      &.mat-primary {
        .mat-select-arrow {
          color: $white;
        }
      }
    }

    &:focus {
      color: $white;
      border-color: $green-primary !important;
      background: $black2;
      box-shadow: none;
    }
  }

  .mat-form-field-flex {
    @extend .abc-input;
  }

  .mat-form-field-appearance-legacy {

    .mat-form-field-wrapper,
    .mat-form-field-infix {
      padding: 0;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-select-arrow {
      color: $placeholder-text;
    }

    .mat-form-field-label {
      color: $placeholder-text;
      font-size: 12px;
    }
  }

  .select-day {
    width: 16.67%;
  }

  .select-month,
  .select-year {
    width: 37%;
  }
}

bs-datepicker-container {
  z-index: 3 !important;
}

.abc-datepicker {
  &.theme-default {
    .bs-datepicker-head {
      background-color: $green-primary;
    }

    .btn-today-wrapper,
    .btn-clear-wrapper {
      .btn-success {
        background-color: $green-primary;
        border-color: $green-primary;

        &:hover {
          background-color: $green-primary;
          border-color: $green-primary;
        }

        &:focus {
          box-shadow: none;
        }

        &:not(:disabled) {
          &:not(.disabled) {
            &:active {
              background-color: $green-dark;
              border-color: $green-dark;

              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }
    }

    .bs-datepicker-predefined-btns {
      button {
        &.selected {
          background-color: $green-primary;
        }
      }
    }

    .bs-datepicker-body {
      table {
        td {
          span {
            &.selected {
              background-color: $green-primary;
            }

            &[class*="select-"] {
              &:after {
                background-color: $green-primary;
              }
            }
          }

          &[class*="select-"] {
            span {
              &:after {
                background-color: $green-primary;
              }
            }
          }

          &.selected {
            span {
              background-color: $green-primary;
            }
          }

          &.week {
            span {
              color: $green-primary;
            }
          }

          &.active-week {
            span {
              &:hover {
                cursor: pointer;
                background-color: $green-primary;
              }
            }
          }
        }
      }
    }

    .bs-timepicker-in-datepicker-container {
      table {
        margin-left: auto;
        margin-right: auto;
        width: max-content;
      }
    }
  }
}

/* required for Upload video page tab design */

/* Tabbing  */
.abc-upload-video-content-tabs {
  .nav-tabs {
    background-color: #000000;
    border-bottom: 0px;

    .nav-item {
      margin-bottom: 0px !important;
      background-color: #8ac6bd !important;
      width: 33.33%;
      display: flex;

      // align-items: center;
      @include bp(sm-max) {
        border-right: 1px solid #fff;

        &:nth-child(3) {
          border-right: 0px solid #fff;
        }
      }

      &:nth-child(3) {
        .nav-link {
          &::before {
            display: none;
          }
        }
      }

      .nav-link {
        width: 100%;
        font-size: 10px;
        border: 0px !important;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        color: #0c0c0c;
        position: relative;
        padding: 10px 10px 10px 10px;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        line-height: normal;
        margin-bottom: 0px;

        &:hover {
          border-color: unset !important;
          isolation: unset;
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 25px;
          right: -20px;
          top: 0px;
          height: 100%;
          clip-path: polygon(25% 0, 80% 50%, 25% 100%, 0 100%, 0 0);
          z-index: 1;

          @include bp(sm-max) {
            display: none;
          }
        }

        &::before {
          right: -21px;
          background: #ffffff;
        }

        &::after {
          background: #8ac6bd;
        }

        &.active,
        &.completed {
          color: #fff !important;
          background-color: #05947f !important;
          text-decoration: underline;

          &::before {
            display: none;
          }

          &::after {
            background: #05947f;
          }
        }

        @include bp(xlg-min) {
          padding: 9px 14px 9px 15px;
          font-size: 14px;
        }

        @include bp(xxlg-min) {
          padding: 16px 15px 16px 15px;
          font-size: 18px;
        }

        @include bp(xxxlg-min) {
          padding: 21px 15px 21px 15px;
          font-size: 25px;
        }

        @include bp(lg-max) {
          font-size: 14px;
        }

        @include bp(xlgs-max) {
          font-size: 12px;
        }
      }

      @include bp(xxxs-max) {
        display: flex;
        align-items: center;

        &.nav-item:has(.active) {
          background-color: #05947f !important;
        }
      }
    }
  }
}

.primary-color {
  color: $green-primary !important;
}