@import "color-variables";

/*----- Common Spacing , Text styling css -----*/

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mt-10 {
  margin-top: 10px;
  @include bp(sm-max) {
    margin-top: 5px;
  }
}

.mb-10 {
  margin-bottom: 10px;
  @include bp(sm-max) {
    margin-bottom: 5px;
  }
}

.mt-15 {
  margin-top: 15px;
  @include bp(sm-max) {
    margin-top: 15px;
  }
  @include bp(sm-max) {
    margin-top: 15px;
  }
}

.mb-15 {
  margin-bottom: 15px;
  @include bp(sm-max) {
    margin-bottom: 10px;
  }
}

.mt-20 {
  margin-top: 20px;
  @include bp(sm-max) {
    margin-top: 15px;
  }
}

.mb-20 {
  margin-bottom: 20px;
  @include bp(sm-max) {
    margin-bottom: 15px;
  }
}

.mt-25 {
  margin-top: 25px;
  @include bp(sm-max) {
    margin-top: 20px;
  }
}

.mb-25 {
  margin-bottom: 25px;
  @include bp(sm-max) {
    margin-bottom: 20px;
  }
}
.mt-30 {
  margin-top: 30px;
  @include bp(sm-max) {
    margin-top: 25px;
  }
}

.mb-30 {
  margin-bottom: 30px;
  @include bp(sm-max) {
    margin-bottom: 25px;
  }
}

.mb-50 {
  @include bp(sm-max) {
    margin-bottom: 40px;
  }
  margin-bottom: 50px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.abc-line {
  border: 1px $black-primary solid;
}

.trans {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
a {
  outline: 0;
  cursor: pointer;
}
.abc-inner-top-spacing {
  padding-top: 173px;
  @include bp(sm-max) {
    padding-top: 130px;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.abc-success-msg {
  padding: 5px;
  background: $green-primary;
  text-align: center;
  font-weight: 500;
}

/*----- Abc-text-truncate class -----*/

.abc-text-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/*----- Abc-label-no-content-found -----*/

.abc-no-content-found {
  background: $black1;
  padding: 100px 15px;
  text-align: center;
  @include bp(sm-max) {
    padding: 50px 15px;
  }
}

.abc-captcha {
  > div {
    @media (max-width: 320px) {
      transform: scale(0.95);
      transform-origin: 0 0;
    }
  }
}

/*----- Common font-style ------*/

.green {
  color: $green-primary !important;
}

.abc-font-style-12 {
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 16px;
  color: $white;
  margin-top: 5px;
  @include bp(xlg-min) {
    margin-top: 10px;
  }
  @include bp(xxlg-min) {
    margin-top: 5px;
  }
  &.green {
    color: $green-primary;
  }
}
.abc-font-style-10 {
  font-size: 10px;
  letter-spacing: 1px;
  line-height: 16px;
  color: $white;
  margin-top: 5px;
  &.green {
    color: $green-primary;
  }
}

.abc-font-style-18 {
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 25px;
  color: $white;

  @include bp(xlg-min) {
    font-size: 14px;
    line-height: 18px;
  }
  @include bp(xxlg-min) {
    font-size: 18px;
    line-height: 22px;
  }
  @include bp(xxxlg-min) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 14px;
  }
  @include bp(sm-max) {
    font-size: 16px;
    line-height: 22px;
  }

  &.green {
    color: $green-primary;
  }
}

.abc-green-font {
  color: $green-primary;
}

.abc-red-font {
  color: $red;
}

.abc-info-note {
  position: absolute;
  top: 105%;
  right: 0;
}

/*----- Box-shadow -----*/

.abc-banner-top-box-shadow {
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("#{$cdnUrl}/assets/images/abc-banner-top-shadow.png")
      no-repeat;
    z-index: 2;
    @include bp(sm-max) {
      display: none;
    }
  }
}
.abc-banner-full-box-shadow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/*----- Green common overlay style -----*/

.green-common-overlay {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($green-primary, 0.8);
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }
  &:hover {
    &::after {
      opacity: 1;
      visibility: visible;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  &.overlay-play {
    &::before {
      position: absolute;
      height: 34px;
      width: 34px;
      content: "";
      background: url("#{$cdnUrl}/assets/images/abc-talkies-sprite.svg")
        no-repeat -82px -385px;
      top: 50%;
      left: 50%;
      display: block;
      z-index: 2;
      -webkit-transform: translate(-50%, 0%);
      transform: translate(-50%, 0%);
      -webkit-transition: all 0.4s 0.1s;
      transition: all 0.4s 0.1s;
      opacity: 0;
      visibility: hidden;
    }
    &:hover {
      &::before {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.has-bottom-shadow {
  overflow: hidden;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("#{$cdnUrl}/assets/images/abc-teaser-shadow.png") no-repeat
      bottom left;
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 5px;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }
}

/*----- Link hover effect -----*/

.abc-border-left-hover-effect,
.abc-border-center-hover-effect {
  @include bp(lg-min) {
    position: relative;
  }
  &:after {
    @include bp(lg-min) {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      background: $green-primary;
      height: 1px;
      width: 0;
      -webkit-transition: all 400ms;
      transition: all 400ms;
      backface-visibility: hidden;
    }
  }
  &:hover {
    &:after {
      @include bp(lg-min) {
        width: 100%;
      }
    }
  }
}
.abc-border-center-hover-effect {
  &:after {
    @include bp(lg-min) {
      right: 0;
      margin: 0 auto;
    }
  }
}

/*----- abc-common-heading css -----*/

.abc-common-heading-wrapper {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid $green1;
  z-index: 2;
  .section-title {
    font-weight: 700;
    letter-spacing: 0;
    padding: 0 15px;
    display: inline-block;
    position: relative;
    line-height: 30px;
    @include bp(xxxlg-min) {
      padding: 10px 15px;
    }
    @include bp(xxxxlg-min) {
      padding: 30px 35px;
    }
    @include bp(sm-max) {
      line-height: 22px;
      width: calc(100% - 40px);
    }
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      width: 5px;
      height: 100%;
      background: $green1;
      content: "";
      z-index: -1;
      @extend .trans;
    }
  }
  &.border-0 {
    .section-title {
      &::before {
        display: none;
      }
    }
  }
}

.abc-heading-right-part {
  padding-right: 85px;
  position: relative;
  top: 5px;
  .filter-block {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .abc-input-field {
      margin-right: 20px;
      display: inline-flex;
      align-items: flex-start;
      @include bp(md-max) {
        margin-bottom: 5px;
      }
      i[class$="-icon"] {
        display: inline-block;
        margin-right: 10px;
        line-height: 0;
        position: relative;
        top: -5px;
        background-image: url("#{$cdnUrl}/assets/images/abc-talkies-sprite.svg");
        background-repeat: no-repeat;
      }
      .language-icon {
        background-position: -256px 0;
        width: 31px;
        height: 26px;
      }
      .clock-icon {
        background-position: -296px 0;
        width: 22px;
        height: 26px;
      }
      .mask-icon {
        background-position: -285px -106px;
        width: 25px;
        height: 31px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.abc-common-hover-for-heading {
  @include bp(lg-min) {
    &:hover {
      .abc-common-heading-wrapper {
        .section-title {
          &::before {
            width: 100%;
          }
        }
      }
    }
  }
}

/*----- Customize icons CSS -----*/

.edit-icon,
.save-icon {
  position: relative;
  width: 41px;
  height: 41px;
  display: inline-block;
  border-radius: 100%;
  background-color: $black4;
  overflow: hidden;
  cursor: pointer;
  &:before {
    content: "";
    background: url("#{$cdnUrl}/assets/images/abc-talkies-sprite.svg");
    background-position: -573px -174px;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $green-primary;
    border-radius: 100%;
    transform: scale(0);
    transition: all 400ms;
    z-index: 1;
    backface-visibility: hidden;
  }
  &:hover {
    &:after {
      transform: scale(1);
    }
  }
}

.save-icon {
  &::before {
    background-position: -573px -99px;
    width: 16px;
    height: 16px;
  }
}

/*----- Validation Error Style -----*/

.validation-error {
  position: absolute;
  font-size: 12px;
  background: $red;
  padding: 2px 10px;
  top: 100%;
  z-index: 2;
  @include bp(xxxlg-min) {
    font-size: 15px;
  }
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    border-left: 10px solid transparent;
    border-top: 10px solid transparent;
    border-right: 10px solid $red;
    transform: rotate(225deg);
  }
}

/*----- Owl Slider next/prev icon CSS -----*/

/*--- For Home page ---*/

.abc-box-office-inner-slider-warpper {
  .abc-owl-slider {
    .owl-theme {
      .owl-nav {
        margin-top: 0;
        position: static;
        @extend .trans;
        @include bp(xl-min) {
          opacity: 0;
          visibility: hidden;
        }
        @include bp(xl-max) {
          display: none;
        }
        div.disabled {
          opacity: 0;
          visibility: hidden;
          background-image: none;
        }
        div {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin: 0;
          padding: 0;
          font-size: 0;
          background: transparent;
          border-radius: 0;
          width: 70px;
          height: 100%;
          z-index: 1;
        }
        .owl-prev,
        .owl-next {
          &::before,
          &::after {
            @extend .trans;
          }
        }
        @include bp(xl-min) {
          .owl-prev {
            left: -40px;
            width: 40px;
            background-image: linear-gradient(
              90deg,
              #000000 23%,
              rgba(0, 0, 0, 0) 100%
            );
            background-position: inherit;
            &:hover {
              background-color: transparent;
              background-image: linear-gradient(
                90deg,
                #000000 23%,
                rgba(0, 0, 0, 0) 100%
              );
              background-position: inherit;
              &::before {
                opacity: 1;
                visibility: visible;
              }
            }
            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 20px;
              background: url(#{$cdnUrl}/assets/images/abc-talkies-sprite.svg)
                no-repeat;
              width: 14px;
              height: 24px;
              background-position: -573px -227px;
              opacity: 0.6;
              will-change: transform, opacity;
              -webkit-transform: translateY(-50%) rotate(180deg);
              transform: translateY(-50%) rotate(180deg);
            }
          }
          .owl-next {
            right: 0;
            background-image: linear-gradient(
              -90deg,
              #000000 23%,
              rgba(0, 0, 0, 0) 100%
            );
            background-position: inherit;
            &:hover {
              background-color: transparent;
              background-position: inherit;
              background-image: linear-gradient(
                -90deg,
                #000000 23%,
                rgba(0, 0, 0, 0) 100%
              );
              &::after {
                opacity: 1;
                visibility: visible;
              }
            }
            &::after {
              content: "";
              position: absolute;
              top: 50%;
              right: 20px;
              background: url(#{$cdnUrl}/assets/images/abc-talkies-sprite.svg)
                no-repeat;
              width: 14px;
              height: 24px;
              background-position: -573px -227px;
              opacity: 0.6;
              will-change: transform, opacity;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
            }
          }
        }
      }
      .owl-dots {
        margin: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -30px;
        @include bp(sm-max) {
          bottom: -20px;
        }
        .owl-dot {
          span {
            background: $black-primary;
            margin: 0 2px;
            width: 50px !important;
            @extend .trans;
          }
          &:hover {
            span {
              background: $green-primary;
            }
          }
          &.active {
            span {
              width: 20px !important;
              background: $green-primary;
            }
          }
        }
      }
    }
    @include bp(xl-min) {
      &:hover {
        .owl-nav {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

/*--- For-inner-pages ----*/

.abc-owl-slider {
  .owl-theme {
    z-index: 3;
    .owl-nav {
      margin-top: 0;
      position: absolute;
      top: 0;
      right: 0;
      > div {
        margin: 0;
        background: transparent;
        background-image: url("#{$cdnUrl}/assets/images/abc-talkies-sprite.svg");
        background-repeat: no-repeat;
        width: 26px;
        height: 26px;
        font-size: 0;
        border-radius: 0;
        -webkit-transition: all 400ms;
        transition: all 400ms;
        &:hover {
          background: transparent;
          background-image: url("#{$cdnUrl}/assets/images/abc-talkies-sprite.svg");
          color: inherit;
        }
        + div {
          margin-left: 10px;
        }
        &.owl-prev {
          background-position: -364px 0;
          &:hover {
            background-position: -364px -32px;
          }
        }
        &.owl-next {
          background-position: -397px 0;
          &:hover {
            background-position: -397px -32px;
          }
        }
      }
    }
    .owl-dots {
      margin: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -40px;
      @include bp(xlg-min) {
        bottom: -50px;
      }
      @include bp(xxxlg-min) {
        bottom: -65px;
      }
      @include bp(sm-max) {
        bottom: -25px;
      }
      .owl-dot {
        span {
          background: $gray4;
          margin: 0 5px;
          width: 30px !important;
          height: 5px !important;
          @extend .trans;
          @include bp(xlg-min) {
            width: 35px !important;
            height: 5px !important;
          }
          @include bp(xxlg-min) {
            width: 50px !important;
            height: 7px !important;
          }
          @include bp(xxxlg-min) {
            width: 70px !important;
            height: 10px !important;
          }
        }
        &:hover {
          span {
            background: $green-primary;
          }
        }
        &.active {
          span {
            width: 14px !important;
            @include bp(xlg-min) {
              width: 20px !important;
            }
            background: $green-primary;
          }
        }
      }
    }
  }
}

// .owl-theme {
//   .owl-dots {
//     .owl-dot {
//       span {
//         }}}}
/*----- User profile image upload CSS (round box with pencil icon)  */

.abc-user-img-warpper {
  position: absolute;
  top: 0;
  width: 92px;
  height: 92px;
  border-radius: 50%;
  border: 2px solid $white;
  overflow: hidden;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .abc-profile-edit {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 100%;
    background: rgba($black, 0.85);
    -webkit-transform: scale(0);
    transform: scale(0);
    &::before {
      content: "";
      background: url("#{$cdnUrl}/assets/images/abc-talkies-sprite.svg");
      background-position: -437px -69px;
      width: 20px;
      height: 21px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -50px;
      margin: auto;
      visibility: hidden;
      opacity: 0;
      transition: all 400ms;
      will-change: transform, opacity;
    }
    p {
      font-size: 13px;
      line-height: 18px;
      color: $white;
      padding: 0 15px;
      font-weight: 600;
      opacity: 0;
      visibility: hidden;
      will-change: opacity;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
    }
  }
  .user-profile-image {
    &::before {
      transform: scale(0.8);
      transform-origin: center bottom;
    }
  }
  &:hover {
    .abc-profile-edit {
      -webkit-transform: scale(1);
      transform: scale(1);
      p {
        visibility: visible;
        opacity: 1;
        transition-delay: 0.3s;
      }
      &::before {
        bottom: 15px;
        opacity: 1;
        visibility: visible;
        transition-delay: 0.5s;
      }
    }
  }
}

/*----- Banner common style -----*/

.abc-common-banner {
  @include bp(lg-max) {
    padding-top: 90px;
    .abc-banner-top-box-shadow {
      &::before {
        display: none;
      }
    }
  }
  @include bp(sm-max) {
    padding-top: 70px;
  }
  .abc-banner-image {
    overflow: hidden;
    height: 550px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    @media screen and (min-width: 1500px) {
      height: 600px;
    }
    @include bp(xxxlg-min) {
      height: 750px;
    }
    @include bp(xxxxllg-min) {
      height: 1300px;
    }
    @include bp(xxxxlg-min) {
      height: 1600px;
    }
    @include bp(xlg-max) {
      height: 480px;
    }
    @include bp(lg-max) {
      height: 360px;
    }
    @include bp(md-max) {
      height: 300px;
    }
    @include bp(sm-max) {
      height: auto;
    }
  }
}
::ng-deep {
  .abc-app-notification-space {
    .abc-common-banner {
      @include bp(sm-max) {
        padding-top: 115px;
      }
    }
  }
}

/*----- abc-video-player CSS ------*/

.abc-video-player-common {
  header,
  footer {
    display: none;
  }
}
.abc-video-player-warpper {
  height: 100vh;
  max-height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  .abc-video-player-content {
    height: 100%;
    padding-top: 0;
    overflow: hidden;
    position: relative;
    .abc-form-button {
      position: absolute;
      z-index: 9;
      padding: 25px 30px 0;
      top: 0;
      left: 0;
      right: 0;
    }
    .preview-label {
      position: absolute;
      margin-top: 40px;
      margin-left: 80px;
      background: rgba(0, 0, 0, 0.7);
      color: #fff;
      padding: 5px 10px;
      font-size: 14px;
      border-radius: 4px;
      z-index: 3;
    }
    .ad-loading-counter {
      position: absolute;
      bottom: 40px;
      right: 10px;
      background: rgba(0, 0, 0, 0.7);
      color: $white-primary;
      padding: 5px 10px;
      font-size: 14px;
      border-radius: 4px;
      z-index: 3;
    }
  }
  // Video Player new look UI styles
  .video-js {
    width: 100%;
    height: 100%;
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    video {
      width: 100%;
      height: 100%;
      position: static;
    }
    .vjs-control-bar {
      background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.8),
        rgba(255, 255, 255, 0)
      );
      opacity: 1;
      transition: background 0.3s ease;
    }
    .vjs-play-progress {
      background-color: $green-dark;
    }
    .vjs-control-bar .vjs-control {
      color: $white-primary;
    }
    .vjs-control-bar:hover {
      background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2));
    }
    .vjs-big-play-button {
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    .vjs-texttrack-settings {
      display: none;
    }
    .vjs-menu li span.vjs-menu-item-text {
      text-transform: capitalize !important;
    }
    .vjs-time-control {
      padding-left: 4px !important ;
      padding-right: 4px !important ;
      min-width: 8px;
    }
    .vjs-current-time {
      display: inline-block !important;
    }

    .vjs-time-divider {
      display: inline-block !important;
    }

    .vjs-duration {
      display: inline-block !important;
    }
  }
  .abc-video-right,
  .abc-video-left {
    background: url(../../assets/images/abc-forward-button.svg) no-repeat;
    background-size: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
  }
  .abc-video-right {
    transform: rotate(180deg);
  }
  .abc-back-button-warpper {
    width: 50px;
    height: 50px;
    background: $green-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    position: absolute;
    z-index: 1;
    top: 30px;
    left: 30px;
    cursor: pointer;
    @include bp(lg-min) {
      top: -100%;
    }
    img {
      max-width: 50%;
    }
    &:hover {
      img {
        animation: back-button 0.5s infinite;
      }
    }
    @include bp(sm-max) {
      width: 36px;
      height: 36px;
    }
  }
  @include bp(lg-min) {
    &:hover {
      .abc-back-button-warpper {
        top: 30px;
      }
    }
  }
}
@keyframes back-button {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(-5px, 0);
  }
}

.abc-img-object-fit-width {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.abc-cursor-not-allowed {
  cursor: not-allowed;
}

.abc-wallet-transaction-history,
.abc-dashboard-graph {
  .abc-uploaded-movies-warpper {
    .abc-listing-table {
      table.dataTable.dtr-inline.collapsed > tbody {
        > tr {
          > td.dtr-control {
            &:before {
              left: -10px;
            }
          }
        }
      }
    }
  }
}

/*----- Custom scrollbar -----*/

.abc-custom-scrollbar {
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: $green-primary $black-primary;
}
.abc-custom-scrollbar::-webkit-scrollbar {
  width: 7px;
  border-radius: 0;
}
.abc-custom-scrollbar::-webkit-scrollbar-track {
  background-color: $black-primary;
  border-radius: 0;
}
.abc-custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: $green-primary;
}

.pt-110 {
  padding-top: 110px !important;
}